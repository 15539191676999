import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'role/';

class RoleService {
  getAll() {
    return axios.post(API_URLA+ 'all/');
  }
  getStd() {
    return axios.post(API_URLA+ 'std/');
  }
}

export default new RoleService();
