<template>
  <div class="bd-example" data-example-id="">
    <div class="form-group row">
      <label class="col-md-2 col-form-label">{{ $t('user.user.role') }}</label>
      <div class="col-md-10">
        <Select2 :disabled="!isnew" v-model="content.user_role" :options="roleList" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('user_role')" class="validerr">{{validerr.get('user_role')}}</span>
      </div>
    </div>

    <div v-if="content.user_role">
      <div v-show="false" v-if="content.id" class="form-group row">
        <label class="col-md-2 col-form-label">ID</label>
        <div class="col-md-10">
          <input class="form-control" disabled="disabled" type="text" v-model="content.id">
        </div>
      </div>
      <div v-if="content.user_role!='integrator'" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.login') }}</label>
        <div class="col-md-10">
          <input class="form-control" :disabled="!isnew" type="search" v-model="content.user_login">
          <span v-if="validerr.get('user_login')" class="validerr">{{validerr.get('user_login')}}</span>
        </div>
      </div>
      <div class="form-group row" v-if="(isnew || user_change_pass) && content.user_role!='integrator'">
        <label class="col-md-2 col-form-label">{{ $t('user.user.password') }}</label>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-10">
              <input class="form-control" type="search" v-model="content.pass">
            </div>
            <div class="col-md-2">
              <button @click="genPassword()">{{ $t('user.user.generatePassword') }}</button>
            </div>
          </div>
          <span v-if="validerr.get('pass')" class="validerr">{{validerr.get('pass')}}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.name') }}</label>
        <div class="col-md-10">
          <input class="form-control" type="text" v-model="content.user_name">
          <span v-if="validerr.get('user_name')" class="validerr">{{validerr.get('user_name')}}</span>
        </div>
      </div>

      <div v-if="content.user_role=='cashier'" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.hide_balance') }}</label>
        <div class="col-md-10 p-2">
          <input type="checkbox" v-if="content.settings" v-model="content.settings.hide_balance">
        </div>
      </div>
      <div v-if="content.user_role=='cashier'" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.hide_stat') }}</label>
        <div class="col-md-1- p-2">
          <input type="checkbox" v-if="content.settings" v-model="content.settings.hide_stat">
        </div>
      </div>

      <div v-if="is_super || content.bill!=currentUser.bill_id || content.user_role=='cashier'" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.comment') }}</label>
        <div class="col-md-10">
          <textarea class="form-control" type="text" v-model="content.user_comment"></textarea>
        </div>
      </div>

      <div v-if="content.user_role=='integrator' && !isnew" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.token') }}</label>
        <div class="col-md-10">
          {{content.token}} <el-button @click="regentoken">{{$t('user.user.regentoken')}}</el-button>
        </div>
      </div>

      <div v-if="content.user_role=='integrator'" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.games') }}</label>
        <div class="col-md-10">
          <label class="pl-5" :key="item.id" v-for="item in gameListActive">
            <input class="m-1" type="radio" :id="item.id" :value="item.id" v-model="content.games" />
            <!--для подключения нескольких игр--><input v-if="false" class="m-1" type="checkbox" :id="item.id" :value="item.id" v-model="content.games" />
            {{item.text}}
          </label>
          <p><span v-if="validerr.get('games')" class="validerr">{{validerr.get('games')}}</span></p>
        </div>
      </div>

      <div v-if="is_super && content.user_role=='super'" class="form-group row">
        <label class="col-md-2 col-form-label">{{ $t('user.user.timezone') }}</label>
        <div class="col-md-10">
          <Select2 v-model="content.timezone" :options="tz_list" :settings="{ width: '100%' }" />
        </div>
      </div>

      <div v-if="content.user_role=='monitor'">
        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.lang') }}</label>
          <div class="col-10">
            <Select2 v-model="content.lang" :options="lang_list" :settings="{ width: '100%' }" />
            <span v-if="validerr.get('lang')" class="validerr">{{validerr.get('lang')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.game') }}</label>
          <div class="col-10">
            <Select2 v-model="content.game_id" :options="gameList" :settings="{ width: '100%' }" />
            <span v-if="validerr.get('game_id')" class="validerr">{{validerr.get('game_id')}}</span>
          </div>
        </div>

        <!-- Настройки форматирования -->
        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.aNumberOf') }}</label>
          <div class="col-10">
            <input class="form-control" type="number" v-model="content.decimal_places">
            <span v-if="validerr.get('decimal_places')" class="validerr">{{validerr.get('decimal_places')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.accuracy') }}</label>
          <div class="col-10">
            <input class="form-control" type="search" v-model="content.accuracy">
            <span v-if="validerr.get('accuracy')" class="validerr">{{validerr.get('accuracy')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label><input type="checkbox" v-model="content.masking">{{ $t('table.table.masking') }}</label><br>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <button @click="rowSave" type="button" class="btn btn-success" :title="$t('user.user.ok')">Ok</button>
          <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('user.user.cancel')">{{ $t('user.user.cancel') }}</button>
        </div>
        <div v-if="!isnew && user_block" class="col-md-2">
          <button v-if="content.status=='active'&& currentUser.id!=content.id" @click="rowSetActiveStatus(false)" type="button" class="btn btn-secondary" :title="$t('user.user.block')">{{ $t('user.user.block') }}</button>
          <button v-if="content.status=='blocked'&& currentUser.id!=content.id" @click="rowSetActiveStatus(true)" type="button" class="btn btn-primary" :title="$t('user.user.unlock')">{{ $t('user.user.unlock') }}</button>
        </div>
        <div v-if="(is_super || is_admin_sb) && !isnew && currentUser.id!=content.id" class="col-md-8">
          <button v-if="content.logged_in" @click="unLogin()" type="button" class="btn btn-warning" :title="$t('user.user.logOut')">{{ $t('user.user.logOut') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserService from "../../services/user.service";
import RoleService from "../../services/role.service";
import GameService from "../../services/game.service";
import {defineEmits, defineProps, inject, onMounted, computed, ref, watch, watchEffect} from "vue";
import {useStore} from "vuex";

const $t = inject('$t')
const $i18n = inject('$i18n')

const store = useStore()

const props = defineProps({
  selRow:  Object,
  type: String
});

function verdata_fn()
{
  let res = new Map([
    ['user_login', {reg: '[A-Za-z0-9]'}],
    ['user_name', {reg: '.+'}],
    ['user_role', {reg: '.+'}],
    ['lang', {reg: '.+', 'at': [{'field': 'user_role', 'val':'monitor'}]}],
    ['game_id', {reg: '[0-9]+', 'at': [{'field': 'user_role', 'val':'monitor'}]}],
    ['decimal_places', {reg: '[0-9]+', 'at': [{'field': 'user_role', 'val':'monitor'}]}],
    ['accuracy', {reg: '[0-9]+', 'at': [{'field': 'user_role', 'val':'monitor'}]}]
  ])

  if(content.value.user_role=='integrator') {
    res = new Map([
      ['user_name', {reg: '.+'}],
      ['games', {reg: '.+'}]
    ])
  }

  return res
}
const verdata = computed(verdata_fn)

const validatas = inject('validatas')
const validerr = ref(new Map())

const content = ref({
  'lang': '',
  'masking': false,
  'games': [],
  'settings': {
    'hide_balance': false,
    'hide_stat': false
  }
})
const myValue = ref('')
const roleList = ref([])

function regentoken()
{
  let data = {'user_id': content.value.id}
  UserService.regentoken(data).then(
      (response) => {
        content.value.token=response.data.token
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function changeRoleFn()
{
  if(content.value.user_role!='monitor') {
    content.value.lang=''
    content.value.game_id=null
    content.value.decimal_places=null
    content.value.accuracy=null
    content.value.maski=null
  }
}
watchEffect(changeRoleFn)

const emit = defineEmits(['selRow', 'onGetRows'])

const tz_list = inject('tz_list')

const set_result = inject('set_result')

const user_change_pass = inject('user_change_pass')
const user_block = inject('user_block')

const currentUser = inject('currentUser')
const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const genPass = inject('genPass')
const SelOneBill = inject('SelOneBill')
const cntSelBill = inject('cntSelBill')

function closeEdit ()
{
  emit('selRow', {is_sel: false})
}

function rowSave()
{
  validerr.value = validatas(content.value, verdata.value)

  if(validerr.value.size>0) {
    return false
  }

  content.value.bill_id=SelOneBill.value

  //убрать для включения выбора нескольких игр
  if(content.value.games.length>0) {
    content.value.games=[content.value.games]
  }
  if(content.value.user_role=='monitor') {
    content.value.games=[]
  }

  UserService.save(content.value).then(
      (res) => {
        if(isnew.value==true) {
          UserService.getOne(res.data.iop_id).then(
              (response) => {
                if(response.data.user_role=='integrator') {
                  let info_str = response.data.user_login+' '+response.data.token
                  set_result(undefined, info_str)
                }
              },
              (error) => {
                set_result(error.response.status, error)
              }
          )
        }

        store.dispatch("auth/changeTZ", content.value.timezone).then(
          () => {
            emit('onGetRows')
            emit('selRow', {is_edit: false})
          }
        )
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function rowSetActiveStatus(active_status)
{
  UserService.setActiveStatus(props.selRow.id, active_status).then(
      () => {
        getOne()
        emit('onGetRows')
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function unLogin()
{
  UserService.unLogin(props.selRow.id).then(
      () => {
        getOne()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getOne()
{
    UserService.getOne(props.selRow.id).then(
      (response) => {
        content.value = response.data;

        if(content.value.games!=null) {
          content.value.games=content.value.games[0] //убрать для включения выбора нескольких игр
        } else {
          content.value.games = []
        }

        content.value.pass = ''
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
}

function getData ()
{
  if (props.type=='edit') {
    getOne()
  }
}

function getRoles()
{
  if(props.type=='add') {
    RoleService.getStd().then(
      (response) => {
        let roles = response.data
        let rolesFin = []
        for (let key in roles) {
          if (SelOneBill.value == 1 && roles[key]['id'].indexOf('super') != -1) {
            rolesFin.push({id: roles[key]['id'], text: $t('user.user.role' + roles[key]['id'])})
          }

          if (SelOneBill.value == 1 && roles[key]['id'].indexOf('admin') != -1) {
            rolesFin.push({id: roles[key]['id'], text: $t('user.user.role' + roles[key]['id'])})
          }

          if (SelOneBill.value != 1 && roles[key]['id'].indexOf('super') == -1) {
            rolesFin.push({id: roles[key]['id'], text: $t('user.user.role' + roles[key]['id'])})
          }
        }

        roleList.value = rolesFin
      },
      (error) => {
        set_result(error.response.status, error)
      })
    } else {
      RoleService.getAll().then(
        (response) => {
          let roles=response.data
          let rolesFin=[]
          for (let key in roles) {
            if(SelOneBill.value==1 && roles[key]['id'].indexOf('super')!=-1) {
              rolesFin.push({id: roles[key]['id'], text: $t('user.user.role'+roles[key]['id'])})
            }

            if(SelOneBill.value==1 && roles[key]['id'].indexOf('admin')!=-1) {
              rolesFin.push({id: roles[key]['id'], text: $t('user.user.role'+roles[key]['id'])})
            }

            if(SelOneBill.value!=1 && roles[key]['id'].indexOf('super')==-1) {
              rolesFin.push({id: roles[key]['id'], text: $t('user.user.role'+roles[key]['id'])})
            }
          }

          roleList.value = rolesFin
        },
        (error) => {
          set_result(error.response.status, error)
        }
      )
  }
}

function genPassword()
{
  content.value.pass=genPass()
}

function isnewfn()
{
  if (props.type=='add') {
    return true
  }
  return false
}
const isnew=computed(isnewfn)

watch(SelOneBill, () => {
  if(cntSelBill.value==1) {
    getRoles()
  }
})

const gameListActive = ref([])
const gameList = ref([])
function getGames(selBill)
{
  GameService.getGamesListByBill(selBill).then(
      (response) => {
        gameList.value=response.data

        gameListActive.value = []
        for(let key in gameList.value)
        {
          let cur = gameList.value[key]

          if(cur.isselect==="1" && cur.game_type=='Keno') {
            gameListActive.value.push(cur)
          }
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

const lang_list = ref(['en', 'es', 'pt']);

const i18nlocale = computed(() => $i18n.locale)
function onChangeLang()
{
  getRoles()
}
watch(i18nlocale, onChangeLang)

onMounted(() => {
  getRoles()
  getData()
  if(SelOneBill.value!==undefined) {
    getGames(SelOneBill.value)
  }
})
</script>

<style scoped>
</style>
