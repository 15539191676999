import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'user/';

class UserService {
  getAll(bill_id, tz) {
    return axios.post(API_URLA + 'all/', {"bill_id": bill_id, "tz": tz});
  }
  getOne(id) {
    return axios.post(API_URLA + 'one/', {"id": id});
  }
  save(data) {
    return axios.post(API_URLA + 'save/', data);
  }
  setActiveStatus(id, active_status) {
    return axios.post(API_URLA + 'setActiveStatus/', {"id": id, "active_status": active_status});
  }
  unLogin(id) {
    return axios.post(API_URLA + 'unLogin/', {"id": id});
  }
  regentoken(data) {
    return axios.post(API_URLA + 'regentoken/', data);
  }
}

export default new UserService();
