<template>
  <button v-if="showEditBut" @click="editRow('edit')" type="button" class="btn btn-primary p-1" :title="$t('user.useractions.changeAccount')">
    <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
  </button>
  <button v-if="showButBlock && currentUser.id!=props.selRow.id" @click="rowSetActiveStatus(selRow.id, false)" type="button" class="btn btn-secondary p-1" :title="$t('user.useractions.block')">
    <div class="icon-size"><font-awesome-icon icon="unlock-alt" /></div>
  </button>
  <button v-if="showButUnblock && currentUser.id!=props.selRow.id" @click="rowSetActiveStatus(selRow.id, true)" type="button" class="btn btn-info p-1" :title="$t('user.useractions.unlock')">
    <div class="icon-size"><font-awesome-icon icon="lock" /></div>
  </button>
</template>

<script setup>
import {computed, defineEmits, defineProps, inject, ref} from "vue";
import UserService from "../../services/user.service";

const set_result = inject('set_result')

const props = defineProps({
  selRow:  Object
});

const emit = defineEmits(['selRow', 'onGetRows'])

const access_groups = inject('access_groups')

const user_block = inject('user_block')

const currentUser = inject('currentUser')
const is_super = inject('is_super')
const selOneBill = inject('selOneBill')

const content = ref("")

function showEditButFn()
{
  //проверка прав блокировки
  if(user_block.value!=true) {
    return false
  }

  if(selOneBill.value==1 && is_super.value!=true && currentUser.value.username!=props.selRow.user_login) {
    return false
  }

  //проверка активности пользователя
  if(props.selRow.status!='active') {
    return false
  }

  return true
}
const showEditBut = computed(showEditButFn)

function showBlockButFn()
{
  //проверка прав блокировки
  if(user_block.value!=true) {
    return false
  }

  if(selOneBill.value==1 && is_super.value!=true) {
    return false
  }

  //проверка активности пользователя
  if(props.selRow.status=='active') {
    return true
  }

  return false
}
const showButBlock = computed(showBlockButFn)

function showUnblockButFn()
{
  //проверка прав блокировки
  if(user_block.value!=true) {
    return false
  }

  if(selOneBill.value==1 && is_super.value!=true) {
    return false
  }

  //проверка активности пользователя
  if(props.selRow.status=='blocked') {
    return true
  }

  return false
}
const showButUnblock = computed(showUnblockButFn)

function editRow (type)
{
  emit('selRow', {is_sel: true, sel_row: props.selRow, type: type})
}

function rowSetActiveStatus(id, active_status)
{
  UserService.setActiveStatus(id, active_status).then(
      () => {
        emit('onGetRows')
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}
</script>

<style scoped>
  button {
    margin: 3px;
  }
</style>
