<template>
  <div v-if="cntSelBill!=1" class="container-fluid">{{ $t('user.users.chooseOneAccount') }}</div>
  <div v-if="cntSelBill==1 && !rows && !is_sel" class="container-fluid">
    <p>{{ $t('user.users.thereAreNo') }}</p>
    <button v-if="!is_sel" @click="onSelRow({is_sel: true, sel_row: selRow, type: 'add'})" class="btn-success">{{ $t('user.users.addUser') }}</button>
  </div>
  <div class="container-fluid" v-if="cntSelBill==1">
    <p></p>
    <button v-if="!is_sel && is_user_create" @click="onSelRow({is_sel: true, sel_row: selRow, type: 'add'})" class="btn-success">{{ $t('user.users.addUser1') }}</button>
    <p></p>
    <vue-good-table v-if="!is_sel && rows.length>0"
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        :row-style-class="rowStyleClassFn"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
          <user-actions v-if="props.column.field == 'actions'" @selRow="onSelRow" @onGetRows="getRows" :selRow="props.row"></user-actions>
          <font-awesome-icon
              v-if="props.column.field == 'user_active_now'" icon="circle"
              :class="{'user_active': props.row.user_active_now=='1', 'user_not_active': props.row.user_active_now!='1'}"/>
      </template>
    </vue-good-table>
  </div>
  <user v-if="is_sel" @selRow="onSelRow" @onGetRows="getRows" :selRow="sel_row" :type="type"></user>
</template>

<script setup>
import UserService from "../../services/user.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {watch, inject, computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

import { VueGoodTable } from 'vue-good-table-next';
import UserActions from "./UserActions.vue"
import User from "./User.vue";

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const set_result = inject('set_result')

const store = useStore()

const is_super = inject('is_super')

const cntSelBill = inject('cntSelBill')

const currentUser = inject('currentUser')

const currentUserTZ = computed( ()=>currentUser.value.timezone)

function rowStyleClassFn(row)
{
  let color_class=''

  if(row.status!='active') {
    color_class+=' blocked_user'
  }

  return color_class
}

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
  {
    label: $t('user.users.login'),
    field: 'user_login',
    filterOptions: {enabled: true, placeholder: $t('user.users.login')},
    width: '250px',
  },
  {
    label: $t('user.users.name'),
    field: 'user_name',
    filterOptions: {enabled: true, placeholder: $t('user.users.name')},
    width: '270px',
  },
  {
    label: $t('user.users.role'),
    field: 'user_role',
    filterOptions: {enabled: true, placeholder: $t('user.users.role')},
    width: '100px',
  },
  {
    label: '',
    field: 'user_active_now',
    html: true,
    width: '20px',
  },
  {
    label: $t('user.users.comment'),
    field: 'user_comment',
    filterOptions: {enabled: true, placeholder: $t('user.users.comment')},
  },
  {
    label: $t('user.users.last_logged_dt'),
    field: 'last_logged_dt_char',
    filterOptions: {enabled: true, placeholder: $t('user.users.last_logged_dt')},
  },
  {
    label: $t('user.users.action'),
    field: 'actions',
    html: true,
    width: '170px',
  }
])

const rows = ref([])

const sel_row = ref({})
const is_sel = ref(false)
const type = ref('')

const is_user_create = inject('is_user_create')
const SelOneBill = inject('SelOneBill')

function onSelRow(params) {
  sel_row.value=params.sel_row
  is_sel.value=params.is_sel
  type.value=params.type
}

function getRows() {
  let selBill = SelOneBill.value
  if(selBill!=undefined && selBill!='') {
    UserService.getAll(selBill, currentUserTZ.value).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        let rwn=[]
        for(let key in response.data) {
          let cur=response.data[key]

          if(cur.bill==currentUser.value.bill_id && cur.user_role!='cashier') {
            cur.user_comment=''
          }

          rwn.push(cur)
        }

        rows.value = rwn;
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

watch(SelOneBill, () => {
  if(cntSelBill.value==1) {
    getRows()
  }
})

onMounted(() => {
  getRows()
})
</script>

<style>
.blocked_user {
  background-color: #c7ced8;
  font-style: italic;
}

.user_active {
  color: green
}

.user_not_active {
  color: #999999;
}
</style>
